<template>
  <b-container
    fluid
    class="d-flex justify-content-center"
  >
    <b-col md="8">
      <b-card title="Criar Usuário">
        <validation-observer
          ref="simpleRules"
          #default="{ invalid }"
        >
          <b-form>
            <b-form-group
              label="Nome"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >

                <b-form-input
                  id="name"
                  v-model="request.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nome"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="E-Mail"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="E-mail"
                rules="required|email"
              >

                <b-form-input
                  id="email"
                  v-model="request.email"
                  :state="errors.length > 0 || !isEmailValid ? false:null"
                  placeholder="E-mail"
                />

                <small
                  class="text-danger"
                >{{ errors[0] }}</small>

                <small
                  v-if="!isEmailValid && errors.length === 0"
                  class="text-danger"
                > Email já está cadastrado </small>
              </validation-provider>
            </b-form-group>

            <b-form-checkbox-group
              v-model="request.permissions"
              stacked
              :options="permissionsOptions"
              switches
            />

            <b-button
              variant="primary"
              :disabled="invalid && isEmailValid"
              class="mt-1"
              @click="createUser"
            >
              Criar Usuário
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
import {
    BButton,
    BCard,
    BContainer,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
} from 'bootstrap-vue';
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import UserService from '@/services/osiris/UserService';

import '@validations';

const permissionsOptions = [
    { value: 2, text: 'Cadastrar Usuários' },
    { value: 3, text: 'Cadastrar Estabelecimentos' },
    { value: 4, text: 'Criar Opt-Ins' },
    { value: 5, text: 'Atualizar Opt-Ins' },
    { value: 6, text: 'Consulta Opt-Ins' },
    { value: 7, text: 'Rejeitar Opt-Ins' },
    { value: 8, text: 'Criar Opt-Out' },
    { value: 9, text: 'Criar Averbações' },
    { value: 10, text: 'Atualizar Avebações' },
    { value: 11, text: 'Buscar Averbações' },
    { value: 12, text: 'Consulta Agenda' },
    { value: 13, text: 'Visualizar Estatísticas' },
    { value: 14, text: 'Visualizar Extrato' },
].sort((a, b) => a.text.localeCompare(b.text));

export default {
    components: {
        BButton,
        BContainer,
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BCol,
        BFormCheckboxGroup,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },

    data() {
        return {
            request: {
                name: '',
                email: '',
                permissions: [],
            },
            permissionsOptions,
            allEmailsRegistred: [],
        };
    },

    computed: {
        isEmailValid() {
            const { request, allEmailsRegistred } = this;

            if (!request.email) {
                return true;
            }

            return !allEmailsRegistred.includes(request.email);
        },
    },

    created() {
        this.isLoading(true);
        this.getAllUsers();
        this.isLoading(false);
    },

    methods: {
        async createUser() {
            const isConfirmed = await this.confirmAnAction('Você realmente deseja criar esse usuário');

            if (!isConfirmed) {
                return;
            }
            const { name, email, permissions } = this.request;

            this.isLoading(true);
            const { status } = await UserService.store({ name, email, permissions });
            this.isLoading(false);

            if (status === 200) {
                this.modalSuccess('Usuário criado com sucesso');
                this.request = {
                    name: '',
                    email: '',
                    permissions: [],
                };
                this.$nextTick(() => this.$refs.simpleRules.reset());
            } else {
                this.modalError('Não foi possível criar esse usuário');
            }
        },

        async getAllUsers() {
            const { data } = await UserService.index();
            this.allEmailsRegistred = data.data.map(item => item.email);
        },
    },
};
</script>
